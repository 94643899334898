import { useTranslation } from 'react-i18next';

import { createMarkup } from '../utils';

export default function Disclaimers({ modelFamily }) {
  const { t } = useTranslation();

  let modelSpecificDisclaimer = t('ModelSpecificDisclaimer$' + modelFamily);
  if (modelSpecificDisclaimer === ('ModelSpecificDisclaimer$' + modelFamily))
    modelSpecificDisclaimer = '';
    
  return (
    <div className="disclaimer mt-4 text-left">
      <p dangerouslySetInnerHTML={createMarkup(t('PhotosAreIllustrative'))} />
      <p dangerouslySetInnerHTML={createMarkup(t('DisclaimerReservation'))} />
      <p dangerouslySetInnerHTML={createMarkup(modelSpecificDisclaimer)} />
    </div>
  );
}
